import React, { useEffect, useContext, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { AppHeader, AppHeaderLogo } from './components/AppHeader';
import { AppLower } from './components/AppLower';
import { ScanResult } from './components/ScanResult';
import { ScanResultContext } from './context/ScanResultContext';
import { ScanContext } from './context/ScanContext';
import { SplashScreen } from './components/SplashScreen';
import { UseCaseContext } from './context/UseCaseContext';
import { UseCaseSelector } from './components/UseCaseSelector';
import { DesktopFooter } from './components/DesktopFooter';
import { MobileFooterContext } from './context/MobileFooterContext';
import { InfoModal } from './components/InfoModal';
import { MultiBarcodeToggle } from './components/MultiBarcodeToggle';
import { FlashButton } from './components/FlashButton';
import anylineLogoSub from './Anyline_Logo_Capture_Opportunities_reinzeichnung_vektor_white.svg';
import './App.css';

import { init } from '@anyline/anyline-js';

let shouldShowTroubleScanningBarcodeInfo;

Modal.setAppElement('#root');

const cutoutYOffsetMap = {
  meter: 55,
  eCard: 55,
  ocr: 0,
  id: 55,
  barcode: 0,
};

const licenses = {
  'js.anyline.com':
    'ewogICJsaWNlbnNlS2V5VmVyc2lvbiIgOiAiMy4wIiwKICAiZGVidWdSZXBvcnRpbmciIDogIm9uIiwKICAibWFqb3JWZXJzaW9uIiA6ICIzNyIsCiAgIm1heERheXNOb3RSZXBvcnRlZCIgOiA5MCwKICAiYWR2YW5jZWRCYXJjb2RlIiA6IHRydWUsCiAgIm11bHRpQmFyY29kZSIgOiB0cnVlLAogICJzdXBwb3J0ZWRCYXJjb2RlRm9ybWF0cyIgOiBbICJBTEwiIF0sCiAgInBsYXRmb3JtIiA6IFsgIkFuZHJvaWQiLCAiaU9TIiwgIldpbmRvd3MiLCAiV2ViIiwgIkpTIiBdLAogICJzY29wZSIgOiBbICJBTEwiIF0sCiAgInNob3dXYXRlcm1hcmsiIDogZmFsc2UsCiAgInRvbGVyYW5jZURheXMiIDogMzAsCiAgInZhbGlkIiA6ICIyMDI1LTA2LTA1IiwKICAibGljZW5zZUlkIiA6ICI2RDdQcG4wYmhZYXVkS25ENjM3eWx5QUlNVFhkM0lRUGRFOXBWYVVjSSIsCiAgImlvc0lkZW50aWZpZXIiIDogWyAianMuYW55bGluZS5jb20iIF0sCiAgImFuZHJvaWRJZGVudGlmaWVyIiA6IFsgImpzLmFueWxpbmUuY29tIiBdLAogICJ3aW5kb3dzSWRlbnRpZmllciIgOiBbICJqcy5hbnlsaW5lLmNvbSIgXSwKICAianNJZGVudGlmaWVyIiA6IFsgImpzLmFueWxpbmUuY29tIiBdLAogICJ3ZWJJZGVudGlmaWVyIiA6IFsgImpzLmFueWxpbmUuY29tIiBdCn0KCkxWVlUxTVN1ZXEyVE9VUVhqUkYvWWJCMXhiSktCdEtERFhkUmw2ZUJvcUxGaHg4NklGbSt2WW9SbTdqZHV5MmNOdnlmVnltZFB0QmlQVzY3UmNJR2xPNzBpd09LZG5NaGpTeDAraldJaGRJSnphelZ5R2s2VEEzcEV6dlU3VzV5eTJtenpVVmREN24yM05ndVZVdVhydzllMTUxcUptYVFWUlJ0SVJOTitEazYvVFo3OGQxMGFaeHJOSmwxMDVCK1FOSzdQZG0xRm5HMEh3dnB6dlJ1Z1F6TUxZSEhQRC9ad0V5N2luNERQbDIvK0JMdEY1cHFtTmNMZFdKc0JNclVkRkF1eFlCQTNBOGQ2eW5lbXJlZ3NPM1BpdlpqZlpLUTRENE9EYTRGUys5Y1VKdncwLzVkQXkxL0tGYWNMSkdaN2JGMDZ5UzVsYWRQcTdlM3VKbnl6QT09',
};

export function getLicense() {
  const hostname = window.location.hostname;
  return licenses[hostname];
}

let anyline;
function mountAnylineJS(
  preset,
  multiBarcodeActive,
  setScanResult,
  setStartScanTime,
  setShowTroubleInfo,
  orientation,
) {
  const anylicense = getLicense();
  if (!anylicense) {
    throw new Error('No licence provided');
  }
  anyline = init({
    anylinePath: 'https://js.anyline.com/release/52.0.0/anylinejs',
    hapticFeedback: true,
    preset: preset,
    viewConfig: {
      outerColor: '000000',
      outerAlpha: 0.8,
      feedbackAnimationStyle: 'path',
      cutouts: [
        {
          cutoutConfig: {
            offset: {
              x: 0,
              y: orientation === 90 ? 50 : cutoutYOffsetMap[preset],
            },
            feedbackStrokeColor: '0099FF',
          },
          cancelOnResult: true,
        },
      ],
    },
    license: anylicense,
    element: document.getElementById('scanner'),
    debugAnyline: true,
  });

  let modalOpen = false;

  anyline.onResult = (result) => {
    shouldShowTroubleScanningBarcodeInfo = false;

    if (modalOpen) return;

    if (preset !== 'all_barcode_formats') {
      anyline.pauseScanning();
    }

    let sanitizedScanResult;

    if (Array.isArray(result.result)) {
      // Remove none required props
      sanitizedScanResult = {
        ...result,
        result: result.result.filter((r) => r?.identifier !== 'blobKey'),
      };
    } else {
      sanitizedScanResult = {
        ...result,
      };
    }

    setScanResult(sanitizedScanResult);
  };

  anyline.startScanning().catch((e) => {
    alert(e.message);
  });

  if (preset === 'legacy_barcode' || preset === 'all_barcode_formats') {
    anyline.onScannedBarcodes = (result) => {
      setScanResult({
        totalScanCount: 0,
        ...result,
      });
    };
  }

  return anyline;
}

const Branding = styled.div`
  z-index: 1199;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

export const AnylineScanner = styled.div.attrs((props) => ({
  hidden: props.hidden || false,
  displayed: props.displayed,
}))`
  height: 100%;
  transition: opacity 0.1s ease;
  opacity: ${(props) => (props.hidden ? '0' : '1')};
  display: ${(props) => (props.displayed ? 'block' : 'none')};
  @media (max-height: 411px) {
    height: 80%;
  }
`;

function App() {
  // eslint-disable-next-line no-restricted-globals
  const [initialized, setInitialized] = useState(false);
  const [orientation, setOrientation] = useState();
  const [multiBarcodeActive, setMultiBarcodeActive] = useState(false);
  const { isResult, setScanResult, clearScanResult } = useContext(ScanResultContext);
  const { setStartScanTime } = useContext(ScanContext);
  const { isExpanded, delayedHidden, delayedShown } = useContext(MobileFooterContext);
  const { currentUseCase } = useContext(UseCaseContext);
  const [showSplash, setShowSplash] = useState(true);
  const [showTroubleInfo, setShowTroubleInfo] = useState(false);
  const [showMulitBarcodeToggle, setShowMulitBarcodeToggle] = useState(true);
  const [anyInstance, setAnyInstance] = useState(undefined);
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    if (!isResult) {
      if (anyline) anyline.dispose();

      setAnyInstance(undefined);
      setOrientation(window.orientation);
      setShowMulitBarcodeToggle(currentUseCase === 'all_barcode_formats');

      if (initialized) {
        mountAJS();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUseCase, clearScanResult]);

  const mountAJS = () => {
    setIsReset(false);
    setInitialized(true);
    const instance = mountAnylineJS(
      currentUseCase,
      multiBarcodeActive,
      setScanResult,
      setStartScanTime,
      setShowTroubleInfo,
      Math.abs(orientation || 0),
    );

    setAnyInstance(instance);
  };

  const onMulitBarcodeActive = (isActive) => {
    setMultiBarcodeActive(isActive);
  };

  const showTroubleScanningBarcodeInfo = () => {
    setStartScanTime(0);

    shouldShowTroubleScanningBarcodeInfo = true;

    setTimeout(() => {
      if (shouldShowTroubleScanningBarcodeInfo) {
        setShowTroubleInfo(true);
      }
    }, 12000);
  };

  useEffect(() => {
    window.addEventListener(
      'orientationchange',
      () => {
        setOrientation(window.orientation);
      },
      false,
    );
  }, []);
  return (
    <>
      <AnylineScanner
        id="scanner"
        hidden={isExpanded || delayedHidden}
        displayed={isExpanded ? !delayedHidden : delayedShown}
      />
      <Modal
        isOpen={showSplash}
        contentLabel="Anyline Web SDK Demo"
        className="Modal"
        overlayClassName="Overlay"
      >
        <SplashScreen
          handleClose={() => {
            mountAJS();
            setShowSplash(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={showTroubleInfo}
        contentLabel="Having trouble scanning"
        className="Modal"
        overlayClassName="Overlay"
      >
        <InfoModal onClose={setShowTroubleInfo}></InfoModal>
      </Modal>
      <Branding>
        <>
          <AppHeader>
            <a href="https://anyline.com" rel="noopener noreferrer" target="_blank">
              <AppHeaderLogo
                hidden={isExpanded || delayedHidden}
                displayed={isExpanded ? !delayedHidden : delayedShown}
                src={anylineLogoSub}
              />
            </a>
          </AppHeader>
          <AppLower>
            {isResult && <ScanResult multiBarcodeActive={multiBarcodeActive} />}
            <DesktopFooter />
          </AppLower>
          <UseCaseSelector
            showTroubleScanningBarcodeInfo={showTroubleScanningBarcodeInfo}
            showMulitBarcodeToggle={showMulitBarcodeToggle}
            setShowMulitBarcodeToggle={setShowMulitBarcodeToggle}
          />
        </>
      </Branding>
      <MultiBarcodeToggle
        visible={showMulitBarcodeToggle}
        onChange={onMulitBarcodeActive}
      ></MultiBarcodeToggle>
      {anyInstance && (
        <FlashButton
          isReset={isReset}
          setIsReset={setIsReset}
          activateFlash={anyInstance.camera.activateFlash}
        ></FlashButton>
      )}
    </>
  );
}

export default App;
